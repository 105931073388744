
import AddressPicker from '@/components/address_picker.vue'
import DeliveryAddressesEmpty from '@/components/address/delivery_addresses_empty.vue'

export default {
  components: { AddressPicker, DeliveryAddressesEmpty }

  computed: {
    addresses: ->
      @$store.state.account.addresses

    loading: ->
      @$store.state.account.loading

    navigate_back_target: ->
      if @$store.getters['checkout/shipping_options'].length > 1
        '/checkout/dispatch'
      else '/basket'

    selected: {
      get: -> @$store.state.checkout.shipping_address
      set: (address) -> @$store.commit('checkout/set_shipping_address', address)
    }
  }

  # data: -> { selected: null }

  created: ->
    @$store.dispatch('account/fetch_addresses')
    @$store.commit('layout/page_nav', {
      title: @$t('checkout_address_header'),
      back_target: @navigate_back_target
    })

  methods: {
    confirm_address: ->
      return @$refs.new_address_button.$el.click() if !@selected.id
      @$router.push('/checkout/confirmation')

    request_address: ->
      @$store.commit('address_picker/open')

    create_address: (new_address) ->
      @$store.dispatch('account/create_address', new_address)
      .then (address) => @selected = address

    remove_address: (id) ->
      @$store.dispatch('account/remove_address', id)

    active_class: (id) ->
      if id == @selected?.id then 'border-active' else ''
  }
}
